import alipay from '@/assets/iconImg/payment/alipay.png'
import unionpay from '@/assets/iconImg/payment/unionpay.png'
import weixinpay from "@/assets/iconImg/payment/weixinpay.png";

export default [
  // {
  //   type: '支付宝', label: '支付宝',
  //   img: alipay,
  //   describe: '实名认证后，可直接通过个人支付宝扫码付款，手续费1.5%。充值成功后将实时到达您指定的账户。',
  // },
  {
    type: '微信', label: '微信',
    img: weixinpay,
    describe: '实名认证后，可直接通过个人微信扫码付款，手续费1.5%。充值成功后将实时到达您指定的账户。',
  },
  {
    type: '银联', label: '银联快捷',
    img: unionpay,
    describe: '添加银行账户后，可直接通过银联借记卡快捷支付，手续费1.5%。充值成功后将实时到达您指定的账户。',
  },
  {
    type: '网关', label: '银联网关',
    img: unionpay,
    describe: '添加银行账户后，可直接通过银联借记卡网关支付（使用U盾，增加额度），手续费1.5%。充值成功后将实时到达您指定的账户。',
  },
]